import { ApolloError } from "@apollo/client"
import { Card, CardContent } from "@material-ui/core"
import { Box, Button, Checkbox, Field, Grid, Icon, Modal, MuiIcon, Typography } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import { compose, withHooks, withStores } from "enhancers"
import { env } from "configs"
import { PageContent } from "layouts"
import { Link } from "react-router-dom"
import userStore from "stores/userStore"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { checkShowPeriodSection, employeeIsHr, gql, paths } from "utils/helper"
import LogoutModal from "./logout-modal"
import { appTitle } from "constants/objects/title"
import ShowAllRequests from "./ShowAllRequests"
import ShowHighlightRequests from "./ShowHighlightRequests"
import PeriodSection from "components/PeriodSection"
import { ShowDetailOn } from "constants/enums/show-detail-on"

const SectionHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
`

const ListContainer = styled(Box)`
  padding: 0px 16px 16px 16px;
`

const CustomIconList = styled(MuiIcon)`
  font-size: 20px;
  margin-left: 8px;
`

const CurrentUser = styled.div`
  width: 100%;
  margin-bottom: 24px;
`

const SectionTitleAction = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
`

const LandingComponent = (props: any) => (
  <>
    <PageContent
      // title="E-claim"
      title={appTitle}
      titleCentered={false}
      // onListClickTo="/claim-requests?tab=WAITING"
      extendedTitleHeight={"240px"}
      overlapHeight={"200px"}
      showDashBoardIcon={props.showDashboard}
    >
      <ListContainer>
        <CurrentUser>
          <Box style={{ position: "relative" }}>
            <Box display="flex" justifyContent="" flexDirection="column">
              <Typography variant="h1" color="White / White">
                สวัสดี
              </Typography>
              <Typography variant="h1" color="White / White">
                {props.currentUserInfo?.employee.firstName + " " + props.currentUserInfo?.employee.lastName}
              </Typography>
            </Box>
            {props.showOldButton && (
              <Box style={{ position: "absolute", right: 0, top: 0 }}>
                <Link
                  to="/history?tab=WAITING"
                  style={{ display: "flex", alignItems: "center", color: AppColor["White / White"] }}
                >
                  <MuiIcon name="Restore" style={{ fontSize: "14px", marginRight: "2px" }} />
                  <Typography variant="subtitle1" color="White / White">
                    ประวัติการเบิก
                  </Typography>
                </Link>
              </Box>
            )}
            <SectionTitleAction>
              {!props.showOldButton && (
                <Box>
                  <Link
                    to="/history?tab=WAITING"
                    style={{ display: "flex", alignItems: "center", color: AppColor["White / White"] }}
                  >
                    <MuiIcon name="Restore" style={{ fontSize: "14px", marginRight: "2px" }} />
                    <Typography variant="subtitle1" color="White / White">
                      ประวัติการเบิก
                    </Typography>
                  </Link>
                </Box>
              )}
              {props.isShowIcon && (
                <Box>
                  <Link
                    to="/claim-requests?tab=WAITING"
                    style={{ display: "flex", alignItems: "center", color: AppColor["White / White"] }}
                  >
                    <MuiIcon name="DescriptionOutlined" style={{ fontSize: "14px", marginRight: "2px" }} />
                    <Typography variant="subtitle1" color="White / White">
                      {`รายการขอเบิก (${props.listAmount})`}
                    </Typography>
                  </Link>
                </Box>
              )}
            </SectionTitleAction>
            {env.COIN_SOURCE === "coin_module" && (
              <Card style={{ marginTop: "24px", boxShadow: " 0 0 1px" }}>
                <CardContent
                  style={{ display: "flex", flexDirection: "column", padding: "16px", alignItems: "center" }}
                >
                  <Typography variant="h4" color={AppColor["Primary/Primary Text"]}>
                    คะแนนคงเหลือปี {props.year}
                  </Typography>
                  <div style={{ marginBottom: "16px", marginTop: "16px", display: "flex", gap: "8px" }}>
                    <Icon name="point" />
                    <Typography variant="Body/32" color={AppColor["Text/Primary Text"]} isCurrency>
                      {props.currentPoint}
                    </Typography>
                    <Typography variant="body1" color={AppColor["Text/Primary Text"]}>
                      คะแนน
                    </Typography>
                  </div>

                  <Typography variant="h4" color={AppColor["Text/Line"]} isCurrency>
                    คิดเป็นเงิน {props.currentPoint} บาท
                  </Typography>
                </CardContent>
              </Card>
            )}
          </Box>
        </CurrentUser>
        {env.LANDING === "highlight" ? <ShowHighlightRequests {...props} /> : <ShowAllRequests {...props} />}

        {env.CAN_LOGOUT && (
          <Box py={6} onClick={props.handleOpenLogoutModal}>
            <Typography
              variant="body1"
              color="Primary/Primary Text"
              align="center"
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              ออกจากระบบ
            </Typography>
          </Box>
        )}
      </ListContainer>
      <LogoutModal isOpen={props.isLogoutModalOpen} onClose={props.handleCloseLogoutModal} />
    </PageContent>
  </>
)

export const API = {
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS {
      landingRequestConfigs {
        layouts
        requests
      }
    }
  `,
  GET_CURRENT_USER: gql`
    query GET_CURRENT_USER {
      getCurrentUser {
        id
        employee {
          id
          employeeCode
          firstName
          lastName
          role
          grade
          employmentStartDate
          employmentType
          probationPeriod
          probationStatus
          functionalDesignation
          currentPoint
          department
          meta
        }
      }
    }
  `,
  GET_CLAIM_REQUEST_LIST: gql`
    query GET_CLAIM_REQUEST_LIST {
      shouldShowClaimList {
        shouldDisplayRequestList
        waitingClaimRequestCount
      }
    }
  `,
  GET_EMPLOYEE_REQUEST_BUDGETS: gql`
    query GET_EMPLOYEE_REQUEST_BUDGETS($employeeId: String!, $childrenName: String!) {
      employeeRequestBudgets(employeeId: $employeeId, childrenName: $childrenName) {
        usage
      }
    }
  `,
  GET_EMPLOYEE_YEARLY_BUDGETS: gql`
    query GET_EMPLOYEE_YEARLY_BUDGETS($filters: JSON) {
      getEmployeeYearlyBudgets(input: { filters: $filters }) {
        id
        employeeCode
        firstName
        lastName
        budget
        dentalBudget
        year
        companyBudget
        companyDentalBudget
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
    settingInfo: stores.settingStore.setting,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo, settingInfo } = props
    const { useQuery, useEffect, useCallback, useMemo, useState, useLazyQuery } = hooks
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false)
    const [allBudget, setAllBudgets] = useState({})
    const [yearlyBudget, setYearlyBudget] = useState({})
    const [notifyBeforeStartAtText, setNotifyBeforeStartAtText] = useState()

    const { data: currentUserQry, refetch } = useQuery(API.GET_CURRENT_USER, {
      fetchPolicy: "network-only",
      onCompleted: (data: any) => {
        userStore.setCurrentUser(data.getCurrentUser)
      },
      onError: (error: ApolloError) => {
        userStore.setCurrentUser(null)
      },
    })
    const { data } = useQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      nextFetchPolicy: "network-only",
    })

    const { data: fetchShouldShowClaimList } = useQuery(API.GET_CLAIM_REQUEST_LIST, { fetchPolicy: "network-only" })

    const currentUser = useMemo(() => currentUserQry?.getCurrentUser, [currentUserQry])
    const userIsHr = useMemo(() => employeeIsHr(currentUser?.employee.role), [currentUser])
    const currentPoint = useMemo(() => currentUser?.employee.currentPoint || 0, [currentUser])
    const config = useMemo(() => data?.landingRequestConfigs, [data])
    const year = useMemo(() => new Date().getFullYear() + 543, [])
    const showButtonSeeAll = useMemo(() => config?.layouts.allRequests.length > 0, [config])
    const handleClickRequest = useCallback((value: any) => {
      paths.claimDetailPath({ name: value.name }).push()
    }, [])

    // const claimRequestConfigs = useMemo(() => {
    //   if (config)
    //     return config.layouts.topRequests.map((name: string) => {
    //       const request = { ...config.requests[name], name }
    //       const usage = config.usage[name]
    //       return {
    //         ...request,
    //         ...usage,
    //       }
    //     })
    //   return []
    // }, [config])

    const totalClaimRequest = useMemo(() => {
      if (!config?.layouts?.allRequests) return 0

      return config.layouts.allRequests
        .map((allRequest: any) => {
          return allRequest.sectionList.reduce((sum: number, section: any) => sum + section.requestTypeList.length, 0)
        })
        .reduce((sum: number, total: number) => sum + total, 0)
    }, [config])

    const handleOpenLogoutModal = useCallback(() => {
      setIsLogoutModalOpen(true)
    }, [])

    const handleCloseLogoutModal = useCallback(() => {
      setIsLogoutModalOpen(false)
    }, [])

    const isShowPeriodSection = useMemo(() => {
      if (!settingInfo) {
        return false
      }
      return checkShowPeriodSection(ShowDetailOn.IN_FIRST_PAGE, settingInfo)
    }, [settingInfo])

    const validateErrorText = useMemo(() => {
      const startClaimRequestEnrollment = settingInfo?.startClaimRequestEnrollment
      const endClaimRequestEnrollment = settingInfo?.endClaimRequestEnrollment
      if (
        settingInfo === null ||
        startClaimRequestEnrollment === null ||
        endClaimRequestEnrollment === null ||
        userIsHr
      ) {
        return undefined
      }
      const currentDate = new Date()
      const startDate = new Date(startClaimRequestEnrollment)
      startDate.setFullYear(currentDate.getFullYear())
      const startDateNextYear = new Date(startClaimRequestEnrollment)
      startDateNextYear.setFullYear(currentDate.getFullYear() + 1)
      const endDate = new Date(endClaimRequestEnrollment)
      endDate.setFullYear(currentDate.getFullYear())
      const startDateString =
        startDate.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
          timeZone: "Asia/Bangkok",
        }) +
        " เวลา " +
        startDate.toLocaleTimeString("th-TH", {
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "Asia/Bangkok",
        })
      const startDateNextYearString =
        startDateNextYear.toLocaleDateString("th-TH", {
          year: "numeric",
          month: "long",
          day: "numeric",
          timeZone: "Asia/Bangkok",
        }) +
        " เวลา " +
        startDateNextYear.toLocaleTimeString("th-TH", {
          hour: "2-digit",
          minute: "2-digit",
          timeZone: "Asia/Bangkok",
        })
      if (currentDate < startDate) {
        return `ไม่สามารถเบิกสวัสดิการประจำปีได้กรุณาเข้าใช้งานใหม่วันที่ ${startDateString}`
      }
      if (currentDate > endDate) {
        localStorage.removeItem("isNotDisplayAgain")
        return `ไม่สามารถเบิกสวัสดิการประจำปีได้กรุณาเข้าใช้งานใหม่วันที่ ${startDateNextYearString}`
      }
      return undefined
    }, [settingInfo, userIsHr])

    useEffect(() => {
      if (env.USER_CAN_SEE_REMAINING_BUDGET) {
        const performAction = () => {
          refetch()
        }
        const intervalId = setInterval(performAction, 15000)
        return () => clearInterval(intervalId)
      }
    }, [refetch])

    const handleChangeCheck = useCallback((event: any) => {
      localStorage.setItem("isNotDisplayAgain", event.target.checked.toString())
    }, [])

    const openModalNotify = useCallback(
      (text: string) => {
        // @ts-ignore
        Modal.alert({
          className: "deleteConFirmModal",
          title: "",
          children: (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <Box justifyContent="center" mt="16px">
                <MuiIcon fontSize="large" color={AppColor["System/Info"]} name="Info"></MuiIcon>
              </Box>
              <Box justifyContent="center" mt="16px">
                <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
                  ใกล้หมดเวลาเบิกสวัสดิการประจำปี
                </Typography>
              </Box>
              <Box justifyContent="flexStart" mt="8px" mb="24px">
                <Typography variant="body1" color="Text/Black2">
                  {text}
                </Typography>
                <Box mt="16px">
                  <Field
                    component={Checkbox}
                    name="isNotDisplayAgain"
                    className="isNotDisplayAgain"
                    label="ไม่ต้องแสดงอีก"
                    onChange={handleChangeCheck}
                  />
                </Box>
              </Box>
            </Box>
          ),
          okButtonLabel: "ตกลง",
          okButtonVariant: "contained",
          buttonWidth: "100%",
        })
      },
      [handleChangeCheck],
    )

    useEffect(() => {
      async function notify() {
        const notifyBeforeStartAt = settingInfo?.notifyBeforeStartAt
        const startClaimRequestEnrollment = settingInfo?.startClaimRequestEnrollment
        const endClaimRequestEnrollment = settingInfo?.endClaimRequestEnrollment
        if (
          settingInfo === null ||
          startClaimRequestEnrollment === null ||
          endClaimRequestEnrollment === null ||
          notifyBeforeStartAt === null ||
          currentPoint === 0
        ) {
          return
        }
        const currentDate = new Date()
        const notifyDate = new Date(notifyBeforeStartAt)
        notifyDate.setFullYear(currentDate.getFullYear())
        const startDate = new Date(startClaimRequestEnrollment)
        startDate.setFullYear(currentDate.getFullYear())
        const endDate = new Date(endClaimRequestEnrollment)
        endDate.setFullYear(currentDate.getFullYear())
        const endDateString =
          endDate.toLocaleDateString("th-TH", {
            year: "numeric",
            month: "long",
            day: "numeric",
            timeZone: "Asia/Bangkok",
          }) +
          " เวลา " +
          endDate.toLocaleTimeString("th-TH", {
            hour: "2-digit",
            minute: "2-digit",
            timeZone: "Asia/Bangkok",
          })
        const isNotDisplayAgain = localStorage.getItem("isNotDisplayAgain")
        if (currentDate >= notifyDate && currentDate >= startDate && currentDate <= endDate) {
          setNotifyBeforeStartAtText(`กรุณาส่งเรื่องเบิกสวัสดิการให้เรียบร้อยก่อนวันที่ ${endDateString}`)
        }
        if (
          (isNotDisplayAgain === null || isNotDisplayAgain === "false") &&
          currentDate >= notifyDate &&
          currentDate >= startDate &&
          currentDate <= endDate
        ) {
          openModalNotify(`กรุณาส่งเรื่องเบิกสวัสดิการให้เรียบร้อยก่อนวันที่ ${endDateString}`)
        }
      }
      notify()
    }, [currentPoint, openModalNotify, settingInfo])
    const [refetchYearlyBudget] = useLazyQuery(API.GET_EMPLOYEE_YEARLY_BUDGETS, {
      onCompleted: (dataBudget: any) => {
        const yearlyBudgetData = dataBudget.getEmployeeYearlyBudgets[0]
        setYearlyBudget(yearlyBudgetData)
      },
      fetchPolicy: "network-only",
    })

    const [refetchBudget] = useLazyQuery(API.GET_EMPLOYEE_REQUEST_BUDGETS, {
      onCompleted: (data: any) => {
        const current = data.employeeRequestBudgets.usage
        setAllBudgets(current)
      },
      fetchPolicy: "network-only",
    })

    useEffect(() => {
      const refetchEmployeeBudget = () => {
        refetchBudget({
          variables: { employeeId: currentUserInfo.employee.id, childrenName: "" },
        })
      }
      if (env.COIN_SOURCE === "native") {
        refetchEmployeeBudget()
      }
    }, [currentUserInfo, refetchBudget])

    useEffect(() => {
      const refetchYearly = () => {
        const variables = {
          filters: {
            code: currentUserInfo.employee.employeeCode,
            fullName: "",
            year: new Date(data?.claimRequestHistoryDetail.createdAt).getFullYear(),
            isApp: true,
          },
        }

        refetchYearlyBudget({ variables: variables })
      }
      if (env.COIN_SOURCE === "native") {
        refetchYearly()
      }
    }, [currentUserInfo, data, refetchYearlyBudget])

    const showDashboard = useMemo(() => {
      return env.SHOW_DASHBOARD
    }, [])

    return {
      // claimRequestConfigs,
      length: totalClaimRequest,
      handleClickRequest,
      currentUserInfo: currentUser,
      userIsHr,
      currentPoint,
      year,
      showButtonSeeAll,
      handleOpenLogoutModal,
      handleCloseLogoutModal,
      isLogoutModalOpen,
      listAmount: fetchShouldShowClaimList?.shouldShowClaimList?.waitingClaimRequestCount,
      isShowIcon: fetchShouldShowClaimList?.shouldShowClaimList?.shouldDisplayRequestList,
      showOldButton: false,
      claimRequests: config?.requests || {},
      budgets: allBudget,
      yearlyBudget: yearlyBudget || {},
      showDashboard,
      layouts: config?.layouts.allRequests || [],
      notifyBeforeStartAtText,
      isShowPeriodSection,
      validateErrorText,
    }
  }),
)

export default enhancer(LandingComponent)
