import { Dialog as MuiDialog, DialogActions, DialogContent, TextareaAutosize } from "@material-ui/core"
import { Box, Button, Field, Icon, Modal, MuiIcon, Select, Typography } from "components"
import { compose, withFormik, withHooks } from "enhancers"
import { isEmpty } from "lodash"
import Textarea from "pages/main/claim-detail/form-field/Textarea"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { toCurrency } from "utils/helper"

const Dialog = styled(MuiDialog)`
  .MuiDialog-paperScrollPaper {
    padding: 0;
    margin: 1vw;
    min-width: 343px;
    overflow: hidden;
    min-height: 500px;
  }
`

const ModalContainer = styled(DialogContent)`
  /*max-width: 500px;
  min-width: 340px;*/
  height: 100%;
  /*min-height: 200px;*/
  padding: 16px;
`

const ContentContainer = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const TitleContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px auto;
  margin-top: 16px;
  margin-bottom: 16px;
`

const LabelContainer = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: row;
`

const ActionContainer = styled(Box)`
  display: flex;
  width: 100%;
  padding: 0px 16px 16px 16px;
`

const ActionButton = styled(Button)`
  width: 100%;
`

interface ApproveModalProps {
  isOpen: boolean
  title: string
  onClose: () => void
  onConfirm: (rejectReason: any, remark?: string) => void
  amount: string
  handleClose: () => void
  handleConfirm: () => void
  reasonRemark: string
  rejectReasons: object[]
  rejectReasonOptions: object[]
}

export const RejectModalComponent = (props: ApproveModalProps) => (
  <Dialog open={props.isOpen} closeAfterTransition>
    <ModalContainer>
      <ContentContainer>
        <Icon width="40px" height="40px" name="Cancel" color={AppColor["Other/Danger"]} />
        <TitleContainer>
          <Typography variant="h3" color="Text/Black2">
            ปฏิเสธการเบิกสวัสดิการ ?
          </Typography>
        </TitleContainer>
        <Box mb="4px">
          <Typography variant="body1" color="Text/Gray Preview">
            ไม่อนุมัติการเบิก
          </Typography>
        </Box>
        <Box mb="16px">
          <Typography variant="h6" color={AppColor["Primary/Primary Text"]}>
            {`${props.title} ${props.amount ? props.amount + " บาท" : ""}`}
          </Typography>
        </Box>
        <LabelContainer mb="8px">
          <Typography variant="body1" color="Text/Black2">
            เหตุผล
          </Typography>
          <Typography variant="body1" color={AppColor["Other/Danger"]}>
            *
          </Typography>
        </LabelContainer>
        <Field
          component={Select}
          name="rejectReason"
          label=""
          placeholder="เหตุผล"
          options={props.rejectReasonOptions}
          fullWidth
          required
        />
        <LabelContainer mt="4px">
          <Typography variant="body1" color="Text/Gray Success">
            {props.reasonRemark}
          </Typography>
        </LabelContainer>
        <LabelContainer mt="16px" mb="8px">
          <Typography variant="body1" color="Text/Black2">
            หมายเหตุ
          </Typography>
          <Typography variant="body1" color={AppColor["Other/Danger"]}>
            *
          </Typography>
        </LabelContainer>
        <Field component={Textarea} name="remark" placeholder="โปรดระบุ" fullWidth />
      </ContentContainer>
    </ModalContainer>
    <DialogActions>
      <ActionContainer>
        <ActionButton
          variant="outlined"
          onClick={props.handleClose}
          mr="16px"
          startIcon={<Icon name="cancel" width="20px" height="20px" color={`${AppColor["Primary/Primary Text"]}`} />}
        >
          ปิดหน้าต่างนี้
        </ActionButton>
        <ActionButton
          variant="contained"
          onClick={props.handleConfirm}
          startIcon={<Icon name="Check_circle" width="20px" height="20px" color="white" />}
        >
          ยืนยัน
        </ActionButton>
      </ActionContainer>
    </DialogActions>
  </Dialog>
)

const enhancer = compose(
  withFormik({}),
  withHooks((props: any, hooks: any) => {
    const { onClose, onConfirm, values, setValues, rejectReasons } = props
    const { useCallback, useMemo } = hooks
    const reasonRemark = useMemo(() => {
      return values.rejectReason?.description || ""
    }, [values.rejectReason?.description])

    const handleClose = useCallback(() => {
      setValues({})
      onClose()
    }, [onClose, setValues])

    const onValidateError = useCallback(() => {
      // @ts-ignore
      Modal.alert({
        className: "deleteConFirmModal",
        title: "",
        children: (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box justifyContent="center" padding={2}>
              <MuiIcon fontSize="large" color={AppColor["Warning"]} name="Warning"></MuiIcon>
            </Box>
            <Box justifyContent="center" padding={4}>
              <Typography variant="h3" color={AppColor["Text/Primary Text"]}>
                เกิดข้อผิดพลาด
              </Typography>
            </Box>
            <Box justifyContent="flexStart" mb="16px">
              <Typography variant="body1" color="Text/Black2">
                กรุณากรอกรายละเอียดให้ครบถ้วน
              </Typography>
            </Box>
          </Box>
        ),
        okButtonLabel: "ตกลง",
        okButtonVariant: "contained",
        buttonWidth: "100%",
      })
    }, [])

    const handleConfirm = useCallback(() => {
      if (!values.rejectReason) {
        onValidateError()
      } else {
        onConfirm(values.rejectReason, values.remark)
        handleClose()
      }
    }, [values.rejectReason, values.remark, onConfirm, handleClose, onValidateError])

    const rejectReasonOptions = useMemo(
      () =>
        rejectReasons?.map((rejectReason: any) => ({
          label: rejectReason.message,
          value: rejectReason,
        })),
      [rejectReasons],
    )

    return {
      ...props,
      handleClose,
      handleConfirm,
      reasonRemark,
      rejectReasonOptions,
    }
  }),
)

const RejectModal = enhancer(RejectModalComponent)

export default RejectModal
